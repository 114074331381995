import React from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage/LandingPage';
import HomePage from './components/HomePage/HomePage';
import About from './components/About';
import ContactUs from './components/ContactUsPage/ContactUs';
import LandingNavBar from './components/LandingPage/LandingNavBar/navbar';
import PersistentDrawerLeft from './components/HomePage/Drawer';
import Footer from './components/Footer/footer';
import QuestionCard from './components/HomePage/QuestionCard';
import { ComponentProvider } from './components/ComponentContext';
import { Search } from '@mui/icons-material';
import { SearchProvider } from './components/Content/SearchContext';
function App() {
  const location = useLocation();

  const shouldRenderNavBarAndFooter = ['/','/about','/contact'].includes(location.pathname);

  return (
    <div className='maincontainer'>
      <ComponentProvider>
        <SearchProvider>
      {shouldRenderNavBarAndFooter && <LandingNavBar />}
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/homepage" element={<HomePage/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      {shouldRenderNavBarAndFooter && <Footer />}
      </SearchProvider> 
      </ComponentProvider>
    </div>
  );
}
 
export default App;
