import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import QuizCompletePage from './QuizCompletePage';
import Content2 from '../Content/Content2';
import ScienceImage from '../../Resources/artificial-intelligence-4389372_1920.jpg';
import MathImage from '../../Resources/math-work-4711302.jpg';
import ComputerImage from '../../Resources/computer-1869236_1920.jpg';
import { useComponent } from '../ComponentContext'; // import the context
import { useContext } from 'react';
import SearchContext from '../Content/SearchContext';
import logo from '../../Resources/AdaptiLearn Logo.png';
import { ReactComponent as DashboardLogo } from '../../Resources/dashboard.svg';
import { ReactComponent as CoursesLogo } from '../../Resources/courses.svg';
import { ReactComponent as PersonalizedCoursesLogo } from '../../Resources/personalizedCourses.svg';
import { ReactComponent as QuizLogo } from '../../Resources/quiz.svg';
import { ReactComponent as LogoutLogo } from '../../Resources/logOut.svg';


import YT from '../videos/YT';
import QuestionCard from './QuestionCard';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      maxWidth: '100%', // Adjust based on your preference
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingLeft: open ? `${drawerWidth}px` : theme.spacing(3), // Adjust right padding instead of margin
      paddingTop: theme.mixins.toolbar.minHeight, // Additional padding to account for AppBar height plus some spacing
      transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '90%', // Always 100%
    }),
  );
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));





export default function PersistentDrawerLeft({signOut}) {
  const { activeComponent, params, isInitialView, changeComponent, setIsInitialView } = useComponent();
  const { searchItem, setIsLoggedIn } = useContext(SearchContext);

  const resetAllStates = () => {
    // Reset states for Quiz
    setQuizCompleted(false);
    setQuizData({});
  
    resetData();
  };

  const navigate = useNavigate();  // Initialize useNavigate

    const initialData = [
        {
            id: 0,
            name: 'Science',
            img: ScienceImage,
            actionType: 'renderComponent',
            actionValue: 'BiologyComponent',
        },
        {
          id: 1,
          name: 'Math',
          img: MathImage,
          actionType: 'renderComponent',
          actionValue: 'BiologyComponent',
      },
      {
        id: 2,
        name: 'Computer',
        img: ComputerImage,
        actionType: 'renderComponent',
        actionValue: 'BiologyComponent',
    },
        // More initial data if necessary...
    ];
    
    const [data, setData] = useState(initialData);
    
        const resetData = () => {
            setData([...initialData]);
            setIsInitialView(true); // Reset to initial view
        };
    
        const handleDataChange = (newData) => {
            setData(newData);
            setIsInitialView(false); // Change to detailed view
        };
            const theme = useTheme();
            const [open, setOpen] = useState(false);

        
            const handleDrawerOpen = () => {
              setOpen(true);
            };
          
            const handleDrawerClose = () => {
              setOpen(false);
            };
          
            const handleListItemClick = (component) => {
              if (component === 'Log out') {
                handleLogout();
                return;
              }
            
              // // Update sidebar selection
              // setSidebarSelection(component);
            
              if (component !== activeComponent) {
                resetAllStates();  
                changeComponent(component);
              }
            
              setOpen(false); 
            };
            
          

            const handleLogout = () => {
            signOut();  // Assuming this function doesn't need to render anything
            setIsLoggedIn(false);
            navigate('/');
            // Navigate or perform additional cleanup here if necessary
            };

            const [quizCompleted, setQuizCompleted] = useState(false);
            const [quizData, setQuizData] = useState({});
          
            const handleQuizComplete = (data) => {
                setQuizData(data);
                setQuizCompleted(true);
            };
            
            return (
              <Box>
                <CssBaseline />
                <AppBar position="fixed" open={open} sx={{ backgroundColor: 'black' }}>
                  <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }}>
                      <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                      AdaptiLearn
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Drawer
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      boxSizing: 'border-box',
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={open}
                >
                  <div>
                  <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  </DrawerHeader>
                  <img src={logo} alt="Logo" style={{ width: '100%', objectFit: 'contain' }} />
                  </div>
                  <Divider />
                  <List>
        {[
          { text: 'Dashboard', icon: <DashboardLogo /> },
          { text: 'Courses', icon: <CoursesLogo /> },
          { text: 'Personalized courses', icon: <PersonalizedCoursesLogo /> },
          { text: 'Quiz', icon: <QuizLogo /> },
          { text: 'Log out', icon: <LogoutLogo /> }
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
      <ListItemButton
        onClick={() => handleListItemClick(item.text)}
        sx={{
          backgroundColor: activeComponent === item.text ? 'black' : 'inherit',
          color: activeComponent === item.text ? 'white' : 'inherit',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
          '& .MuiListItemIcon-root': {
            color: 'white', // Set icon color to white always
          },
        }}
      >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
                </Drawer>
      <Main open={open}>
        {activeComponent === 'Quiz' ? (
          <div style={{width:'100%'}}>
            {!quizCompleted ? (
              <QuestionCard onQuizComplete={handleQuizComplete} subject={searchItem} />
            ) : (
              // setSidebarSelection={setSidebarSelection}
              <QuizCompletePage dataa={quizData} subject={searchItem}  />
            )}
          </div>
        ) : null}
        {activeComponent === 'Dashboard' ? <Content2/> : null}
        {activeComponent === 'Personalized courses' ? (
  <YT
    data={data}
    resetData={resetData}
    onDataChange={handleDataChange}
    isInitialView={isInitialView}
    view={"personalized"}
    subject={params.subject}
  />
) : null}

        {activeComponent === 'Courses' ?   <YT
    data={data}
    resetData={resetData}
    onDataChange={handleDataChange}
    isInitialView={isInitialView}
    view={"default"}
    subject={params.subject}
  /> : null}
        {activeComponent === 'Log out' ? signOut() : null}
      </Main>
    </Box>
  );
}