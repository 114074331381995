import React, { useContext, useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import Card from '../Card/Card';
import SearchContext from './SearchContext';
import { Typography } from '@mui/material';

function PieChartViz() {
  const { searchItem, dashboardData } = useContext(SearchContext);
  const avgtime = dashboardData[searchItem] ? dashboardData[searchItem].PieChart : {};
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const entries = Object.entries(avgtime).map(([subject, time]) => ({
      subject: subject,
      averageTime: parseFloat(time)  // Ensure that time is a number for sorting if necessary
    })).sort((a, b) => a.subject.localeCompare(b.subject));  // Sort alphabetically by subject
    setData(entries);
  }, [avgtime]);

  const colors = ['#ff0000', '#00cc00', '#0000ff'];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          {`${payload[0].value} s`}
        </div>
      );
    }
  
    return null;
  };

  
  return (
    <Card>
      <div style={{
        backgroundColor: "#f9fafb",
        borderRadius: '20px',
        display: 'flex',  // Added to activate Flexbox
        flexDirection: 'column',  // Ensures children are stacked vertically
        justifyContent: 'center',  // Center align children vertically in the container
        alignContent: 'center', width: '100%' ,
        width: '100%',
        paddingTop: '20px'  // Adds some padding inside the div
      }}>
        <Typography sx={{ fontSize: 18, paddingLeft: '20px', fontWeight: 'bold' }} color="text.primary" gutterBottom>
          Average Time Spent on Topics
        </Typography>
        <div style = {{display: 'flex', 
        flexDirection: 'column',  
        justifyContent: 'center', alignItems:'center'}}>
        <PieChart width={300} height={300}>
          <Pie
            dataKey="averageTime"
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={80}
            paddingAngle={5}
            label={({ subject }) => `${subject}`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
        </div>
      </div>
    </Card>
  );
  
}

export default PieChartViz;
