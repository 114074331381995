import { CircularProgress, Card, CardContent, Typography, Box , Grid} from '@mui/material';
import SearchContext from './SearchContext';
import React, { useContext, useState, useEffect } from 'react';

const SubjectCard = ({ subject, score, color }) => {
    const normalizedScore = parseFloat(score);  // Ensure score is a number

    return (
        <div style={{padding: '8px' }}>  {/* Replaces Grid item */}
            <Card sx={{ display: 'flex', borderRadius: '20px', width: '100%'}}>
                <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <Box position="relative" display="inline-flex">
                        <CircularProgress variant="determinate" value={normalizedScore} size={50} thickness={4} sx={{ color: color }} />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: 12 }}>
                                {`${Math.round(normalizedScore)}%`}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: 14, paddingLeft: '20px', fontWeight: 'bold' }} color="text.primary">
                        {subject}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default function Trial() {
  const { searchItem, dashboardData } = useContext(SearchContext);
  const scores = dashboardData[searchItem] ? dashboardData[searchItem].LatestQuiz : {};
  const colors = ['#ff0000', '#00cc00', '#0000ff'];

  // Create an array from scores object and sort it alphabetically by subject
  const sortedScores = Object.entries(scores)
      .sort((a, b) => a[0].localeCompare(b[0]));  // Sort by the subject name alphabetically

  return (
      <div style={{ backgroundColor: "#f9fafb", borderRadius: '20px', margin: 'auto', alignContent: 'center', width: '100%' }}>
          <Typography sx={{ fontSize: 18, padding: '20px', fontWeight: 'bold' }} color="text.primary" gutterBottom>
              {"Latest Quiz Results"}
          </Typography>
          <Grid container spacing={3} display={'flex'} flexDirection={"column"} marginLeft={'0px'} width={'100%'}>
              {sortedScores.map(([subject, score], index) => (
                  <SubjectCard
                      key={subject}
                      subject={subject}
                      score={score}  // Assuming score is an object with a percentage property
                      color={colors[index % colors.length]}  // Cycle through colors
                  />
              ))}
          </Grid>
      </div>
  );
}