import React from 'react';
import './contactus.css';
import { Typography } from '@mui/material';
import Button from '../button'; // Make sure this Button component accepts and handles the onClick prop

export default function ContactUs() {
  const handleEmailOpen = () => {
    window.location.href = 'mailto:abubakarx72@gmail.com';
  };

  return (
    <div className="contactUsContainer">
      <Typography variant="h4" className='contactUsTitle' sx={{ textAlign: 'center' }}>
        Still have questions?
      </Typography>
      <Typography variant="h6" className='contactUsSubtitle' sx={{ textAlign: 'center' }}>
        Can't find the answer you're looking for? Please contact us.
      </Typography>
      <Button onClick={handleEmailOpen}>Contact us</Button>
    </div>
  );
}
