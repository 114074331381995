import abu from '../../../Resources/WhatsApp Image 2023-10-06 at 23.21.16.jpeg'
import { Typography } from '@mui/material';
export default function reviewer() {  
    return (
        <div className="reviewerContainer">
        <img className="reviewer-Img" src={abu}/>
        <Typography variant="h5" sx={{textAlign:'center',paddingTop:'10px',fontWeight:'650'}}>Muhammad Abubakar</Typography>
        <Typography variant="h6" sx={{textAlign:'center',fontWeight:'500'}}>Founder, AdaptiLearn</Typography>
        </div>
    );
  }
  
  