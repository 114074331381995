import * as React from 'react';
import { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { post } from 'aws-amplify/api';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircularTimer from './CircularTimer';
import Button from '../button';
import QuizStart from './QuizStart';

// Custom Radio Button with Black Color
const BlackRadio = (props) => (
    <Radio
        sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}
        {...props}
    />
);

export default function QuestionCard({ subject,onQuizComplete }) {
    const timerDuration = 60; // 60 seconds;
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [showExplanation, setShowExplanation] = useState(false);
    const [disabledAnswers, setDisabledAnswers] = useState([]);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [timerRunning, setTimerRunning] = useState(true);
    const [timerReset, setTimerReset] = useState(false);
    const [timeUp, setTimeUp] = useState(false);
    const [scores, setScores] = useState({}); // Scores by topic
    const [timeLeftAtAnswer, setTimeLeftAtAnswer] = useState(timerDuration); // Time left when answer is selected


    const [isQuizActive, setIsQuizActive] = useState(false); // Tracks if the quiz is currently active
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);
    const [showStartScreen, setShowStartScreen] = useState(true); // New state for start screen visibility
    const [isLoading, setIsLoading] = useState(false);
    const [correctFirstAttempts, setCorrectFirstAttempts] = useState(0);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isQuizActive && !isQuizCompleted) {
                // Only prompt if the quiz is active and not completed
                const message = "You are in the middle of a quiz. If you leave, your progress will not be saved. Continue?";
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isQuizActive, isQuizCompleted]);


    useEffect(() => {
        if (timerReset) {
            setTimerReset(false);
        }
    }, [timerReset]);

        // Start the quiz by fetching questions
        const startQuiz = async () => {
            setShowStartScreen(false); // Hide the start screen
            setIsLoading(true); // End loading
            const APIName = "AdaptiLearnRestAPI";
            const apiPath = '/getQuestions';
            try {
                const response = await post({ apiName: APIName, path: apiPath, options: { body: { "subject": subject } } });
                const reply = (await response.response).body;
                const data = await reply.json();
                setQuestions(data);
                setIsQuizActive(true);
                setTimerRunning(true); // Start the timer immediately after loading questions
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally{
            setIsLoading(false); // End loading
            }
        };

        if (showStartScreen) {
            return(<QuizStart startQuiz={startQuiz} />);
        }

if (isLoading) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
            <Skeleton variant="rectangular" width={100} height={100} style={{ alignSelf: 'center' }} />
            <Typography component="div" variant="h5">
                <Skeleton width="40%" height={40} />
            </Typography>
            <Typography component="div" variant="h5" style={{ margin: '20px 0' }}>
                <Skeleton width="90%" height={40} />
            </Typography>
            {[1, 2, 3, 4].map((option) => (  // Assume 4 options as a default, adjust if necessary
                <Typography component="div" key={option} variant="body1" style={{ marginBottom: 10 }}>
                    <Skeleton width="100%" height={50} />
                </Typography>
            ))}
            <Skeleton variant="rectangular" width="100%" height={50} style={{ alignSelf: 'center', marginTop: 20 }} />
        </div>
    );
}

    async function updateUserWeights(scores) {
        const APIName = "AdaptiLearnRestAPI";
        const apiPath = '/updateUserWeights';
        const formattedScores = {};
    
        // Format scores to include 'value' and 'isWeakness'
        Object.keys(scores).forEach(topic => {
            formattedScores[topic] = {
                percentage: scores[topic].percentage,
                isWeakness: scores[topic].isWeakness,
                averageTimeTaken: scores[topic].averageTimeTaken,
            };
        });

        formattedScores.subject = subject;
        try {
            const response = await post({ apiName: APIName, path: apiPath, options: { body: formattedScores } });
            const reply = (await response.response).body;
            const data = await reply.json();
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    const currentQuestion = questions.length > 0 ? questions[currentQuestionIndex] : null;

const handleTimerEnd = () => {
    if (!timeUp){
    const topic = currentQuestion.topic;

    setScores(prevScores => {
        const topicScores = prevScores[topic] || {
            scored: 0,
            totalPossible: 0,
            totalQuestions: 0,
            totalTimeTaken: 0
        };

        const newTotalPossible = topicScores.totalPossible + timerDuration;
        const newTotalQuestions = topicScores.totalQuestions + 1;
        const newTotalTimeTaken = topicScores.totalTimeTaken + timerDuration;
        const newAverageTimeTaken = newTotalTimeTaken / newTotalQuestions;
        const newPercentage = (topicScores.scored / newTotalPossible) * 100;
        const isWeakness = newPercentage < 50;

        return {
            ...prevScores,
            [topic]: {
                scored: topicScores.scored,
                totalPossible: newTotalPossible,
                totalQuestions: newTotalQuestions,
                totalTimeTaken: newTotalTimeTaken,
                percentage: newPercentage.toFixed(2),
                averageTimeTaken: newAverageTimeTaken.toFixed(2),
                isWeakness
            }
        };
    });

    setShowExplanation(true);
    setSelectedAnswer(currentQuestion.correct_answer);
    setDisabledAnswers(Object.keys(currentQuestion.options));
    setTimerRunning(false);
    setTimeUp(true);
    setTimerReset(true);
}
};

const handleCheckAnswer = () => {
    const topic = currentQuestion.topic;
    if (selectedAnswer === '') {
        return;
    }
    const correct = selectedAnswer === currentQuestion.correct_answer;

    if (correct) {
        setIsAnswerCorrect(true);
        setTimerRunning(false);

        const initialWrongAttempt = disabledAnswers.length > 0;
        if (!initialWrongAttempt) {
            setCorrectFirstAttempts(prev => prev + 1);
        }
        const scoreToAdd = initialWrongAttempt ? 0 : (timeUp ? 0 : timeLeftAtAnswer);
        
        setScores(prevScores => {
            const newScored = (prevScores[topic]?.scored || 0) + scoreToAdd;
            const newTotalPossible = (prevScores[topic]?.totalPossible || 0) + timerDuration;
            const newPercentage = (newScored / newTotalPossible) * 100;
            const totalQuestions= (prevScores[topic]?.totalQuestions || 0) + 1;
            const totalTimeTaken= (prevScores[topic]?.totalTimeTaken || 0) + (timerDuration-timeLeftAtAnswer);
            const averageTimeTaken=totalTimeTaken/totalQuestions;
            const isWeakness = newPercentage < 50;

            return {
                ...prevScores,
                [topic]: {
                    scored: newScored,
                    totalPossible: newTotalPossible,
                    totalQuestions: totalQuestions,
                    totalTimeTaken: totalTimeTaken,
                    percentage: newPercentage.toFixed(2),
                    averageTimeTaken: averageTimeTaken.toFixed(2),
                    isWeakness
                }
            };
        });


        setDisabledAnswers(Object.keys(currentQuestion.options));
    } else {
        setDisabledAnswers(prev => [...prev, selectedAnswer]);
    }
    setShowExplanation(true);
};
    
    const handleRadioChange = (event) => {
        setSelectedAnswer(event.target.value);
        setShowExplanation(false);
        setIsAnswerCorrect(false);
    };



    const handleNextQuestion = () => {
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex < questions.length) {
            setCurrentQuestionIndex(nextIndex);
            setSelectedAnswer('');
            setShowExplanation(false);
            setDisabledAnswers([]);
            setIsAnswerCorrect(false);
            setTimeLeftAtAnswer(timerDuration);
            setTimerReset(true);
            setTimeUp(false);
            setTimerRunning(true);
        } else {
            handleFinishQuiz();
        }
    };

const handleFinishQuiz = () => {
    updateUserWeights(scores);
    setIsQuizCompleted(true);
    setIsQuizActive(false); // Quiz ends, so deactivate it

    onQuizComplete({
        scores: scores,
        correctFirstAttempts: correctFirstAttempts,
        topicsWellDone: ["physics",], // Calculate or fetch topics well done
        topicsToImprove: ["biology","chemistry"] // Calculate or fetch topics to improve
    });
};


    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', justifyContent: 'center', gap: '10px', width: "100%"}}>
            <CircularTimer duration={timerDuration} running={timerRunning} reset={timerReset} onTimerEnd={handleTimerEnd} onTimeUpdate={(time) => setTimeLeftAtAnswer(time)} />
            {showExplanation && (
                <div style={{ marginBottom: '20px', padding: '20px', borderRadius: '10px', backgroundColor: timeUp ? '#ffff99' : (selectedAnswer === currentQuestion.correct_answer ? '#acd2cc' : '#fcbca0') }}>
                    {timeUp ? `Time up! ${currentQuestion.explanation[currentQuestion.correct_answer]}` : currentQuestion.explanation[selectedAnswer]}
                </div>
            )}

            <div style={{ flex: 1 }}>
                <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>Question {currentQuestionIndex + 1}: </p>
                <p style={{ marginBottom: '10px' }}> {currentQuestion.question}</p>
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginLeft: '11px', marginRight: '-11px' }}>
                <FormControl fullWidth>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedAnswer}
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        {Object.entries(currentQuestion.options).map(([key, value]) => (
                            <FormControlLabel
                                key={key}
                                value={key}
                                control={<BlackRadio disabled={disabledAnswers.includes(key) || timeUp} />}
                                label={value}
                                sx={{
                                    width: '100%',
                                    border: '1px solid black',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    '&:hover': {
                                        backgroundColor: 'lightgray',
                                    },
                                    '.MuiFormControlLabel-label': {
                                        flexGrow: 1
                                    }
                                }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
            <button
                style={{ marginTop: '20px', padding: '10px', backgroundColor: 'black', color: 'white', border: 'none', borderRadius: '4px' }}
                onClick={(isAnswerCorrect || timeUp) ? handleNextQuestion : handleCheckAnswer}
            >
                {((isAnswerCorrect || timeUp) ? ((currentQuestionIndex + 1  === questions.length) ? "Finish Quiz" : "Next Question") : "Check Answer")}
            </button>
        </div>
    );
}
