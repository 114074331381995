import React, { useState } from 'react';
import { Container, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { ReactComponent as MySvg } from '../../Resources/sitting-computer.svg';
import Button from '../button';
import { OptionSelector } from '../Content/Content2.js';
import { useContext } from 'react';
import SearchContext from '../Content/SearchContext';


const QuizStart = ({startQuiz, subject = null}) => {
    const { searchItem } = useContext(SearchContext);
    const [screen, setScreen] = useState(1); // State to track current screen
    const theme = useTheme();
    // Media query that returns true if the screen width is less than 'sm'
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleNext = () => {
        setScreen(screen => screen + 1);
    };

    // Define dynamic styles based on screen size
    const dynamicStyles = {
        paddingTop: isMobile ? 0 : '15%',
        paddingBottom: isMobile ? 0 : '15%',
        height: isMobile ? 0 : '100%'
    };

    const dynamicMargin = {
        marginTop: isMobile ? 0 : 'auto'
    }


    return (
        <Container maxWidth="lg" style={{ height: '80vh' }}>
            <Grid container alignItems="center" justifyContent="center" gap={'10px'}>
                <div style={{ display: 'flex', flexDirection: 'column', ...dynamicStyles, gap: '10px',height: '100%' }}>
                    {screen === 1 ? (
                        <div style={{display:'flex',flexDirection:'column', alignItems:"left"}}>
                            <Typography variant="h2"  gutterBottom>Welcome to the Quiz!</Typography>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                Get ready to test your knowledge and have fun!
                            </Typography>
                            <Button onClick={handleNext}>
                                Next
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Typography variant="h2" gutterBottom>Quiz</Typography>
                            <div style={{display:'flex', gap:'10px',alignItems:'left'}}>
                            <Typography variant="h5" color="text.primary" sx={{alignContent:'center'}}>
                                Select a Subject:
                            </Typography>
                            {(subject !== null? <OptionSelector initialSubject={searchItem}/> : <OptionSelector initialSubject={searchItem}/> )}
                            </div>
                            <Typography variant="h5" color="text.primary" >
                                Info:
                            </Typography>
                            <Typography variant="h6" color="text.secondary" >
                                -There are 10 questions from 3 topics from the subject of your choice.
                            </Typography>
                            <Typography variant="h6" color="text.secondary" >
                                -You will be given 60 seconds to answer the questions, so be quick!
                            </Typography>
                            <Typography variant="h6" color="text.secondary" >
                                -The quicker you are, the more points you get.
                            </Typography>
                            <Typography variant="h6" color="text.secondary" >
                                -A minimum score of 50% per topic is required to pass.
                            </Typography>
                            <Typography variant="h6" color="text.secondary" >
                                -If you get an answer wrong, make sure to try again.
                            </Typography>
                            <Typography variant="h6" color="text.secondary" >
                                -Last and most importanly, have fun!
                            </Typography>
                            <Button onClick={startQuiz}>Start Quiz</Button>
                        </>
                    )}
                    <div style={{...dynamicMargin}}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: 10, height: 10, borderRadius: '50%', background: screen === 1 ? '#000' : '#ddd', margin: 5 }}></div>
                            <div style={{ width: 10, height: 10, borderRadius: '50%', background: screen === 2 ? '#000' : '#ddd', margin: 5 }}></div>
                        </div>
                    </div>
                </div>
                <Grid item xs={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <MySvg width={'100%'} height={'100%'}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default QuizStart;
