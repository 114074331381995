import MultiCards from './MultiCards';
import strenghtenWeaknessesImg from '../../../Resources/strenghtenWeaknesses.png'
import aiPoweredQuestionsImg from '../../../Resources/AI Teaching.png'
import handsOnEngagementImg from '../../../Resources/learning.png'
import kani from '../../../Resources/Kanishka.jpg';
import hamzu from '../../../Resources/Hamza.jpg';
import './card.css';
import Button from '../button';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router
import { useComponent } from '../../ComponentContext'; // Adjust the path as necessary to import your context
import { Typography } from '@mui/material';


export default function CardContainer() {
  const navigate = useNavigate();
  const { changeComponent } = useComponent(); // using the context

  const Quiz = () => {
    changeComponent('Quiz'); // Change the component to the homepage
    navigate('/homepage');
  };
      // Array of objects containing the data for each card
  const cardsData = [
    {
      img: strenghtenWeaknessesImg,
      title: 'Harness Your Potential: Empowerment through Adaptive Learning',
      description: 'Transform your challenges into opportunities with AdaptiLearn. Our sophisticated algorithm meticulously identifies gaps in your knowledge, curates targeted resources, and crafts personalized assessments to fortify your understanding. Embark on a tailored educational journey that not only fills the gaps but turns your weaknesses into strengths.'
    },
    {
      img: aiPoweredQuestionsImg,
      title: 'Unleash the Infinite: Explore with AI-Powered Precision',
      description: 'Dive into a world of endless learning possibilities with AdaptiLearn. Our cutting-edge AI technology dynamically generates a constant stream of fresh, tailored questions to challenge and expand your understanding, ensuring every learning session is as engaging as it is educational.'},
    {
      img: handsOnEngagementImg,
      title: 'Supercharge Your Learning: Active Engagement with AdaptiLearn',
      description: 'Embark on a transformative journey of discovery with AdaptiLearn, where mastering your chosen topics becomes an adventure in active participation. Our platform encourages hands-on engagement, enabling you to deeply explore and effectively retain knowledge through dynamic, interactive learning experiences.'
    },
    // Add more card data objects here
  ];

    return (
    <div className="cardContainer" style={{justifyContent:'center', alignItems:'center'}}>
      <Typography variant="h3" sx={{textAlign:'center', fontWeight:600}}>Unlock Your Potential with Revolutionized Learning</Typography>
    <MultiCards cardsData= {cardsData}/>
    <Button onClick={Quiz}>Test Yourself</Button>
    </div>
    );
}