import React, { createContext, useState } from 'react';

const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
    const [searchItem, setSearchItem] = useState("Science");
    const [dashboardData, setDashboardData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);


    return (
        <SearchContext.Provider value={{ searchItem, setSearchItem, dashboardData, setDashboardData,isLoaded, setIsLoaded, isLoggedIn, setIsLoggedIn }}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContext;
