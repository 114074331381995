export default function Button({ onClick, children, style= null }){
    if (style !== null){
        return(
            <a className="Button" onClick={onClick} style={style}>{children}</a>
        );
    }
    return(
    <a className="Button" onClick={onClick}>{children}</a>
    );

}