import './review.css'
import Reviewer from './reviewer'
import { Typography } from '@mui/material';
export default function reviewContainer() {  
    return (
        <div className="reviewContainer">
        <Typography variant="h4" sx={{textAlign:'center', fontWeight:500}}>"This has been an enjoyable and efficient new way of learning for our upcoming youth. It is unimaginable to learn without it."</Typography>
        <Reviewer/>
        </div>
    );
  }
  
  