import React, { createContext, useContext, useState } from 'react';

const ComponentContext = createContext({
  state: {
    activeComponent: 'Dashboard',
    params: {},
    isInitialView: true
  },
  changeComponent: () => {},
  setIsInitialView: () => {} // Add setIsInitialView function to the context
});

export const useComponent = () => useContext(ComponentContext);

export const ComponentProvider = ({ children }) => {
  const [state, setState] = useState({
    activeComponent: 'Dashboard',
    params: {},
    isInitialView: true
  });

  const changeComponent = (componentName, params = {}, isInitialView = true) => {
    setState(prevState => {
      return {
        ...prevState,
        activeComponent: componentName,
        params: params,
        isInitialView: isInitialView
      };
    });
  };
  

  // Create a setter function specifically for isInitialView
  const setIsInitialView = (isInitialView) => {
    setState(prevState => ({ ...prevState, isInitialView }));
  };

  return (
    <ComponentContext.Provider value={{ ...state, changeComponent, setIsInitialView }}>
      {children}
    </ComponentContext.Provider>
  );
};
