import React, { useState } from 'react';
import Button from '../LandingPage/button';
import { CircularProgress, Card, CardContent, Typography, Box , Grid} from '@mui/material';
import YT from '../videos/YT';
import { margin } from '@mui/system';
import { useComponent } from '../ComponentContext'; // import the context

function QuizCompletePage({ dataa, subject}) {
    const { changeComponent } = useComponent(); // using the context
    const { scores, topicsWellDone, topicsToImprove, correctFirstAttempts } = dataa;
    const [selectedTopic, setSelectedTopic] = useState(null);
    const checkMark = "\u2713";
    const crossMark = "\u2717";

    const initialData = [
        {
            id: 0,
            name: 'Science',
            img: 'https://i3.ytimg.com/vi/6v8djXa-IPQ/maxresdefault.jpg',
            actionType: 'renderComponent',
            actionValue: 'BiologyComponent',
        },
        {
            id: 1,
            name: 'Math',
            img: 'https://i3.ytimg.com/vi/6v8djXa-IPQ/maxresdefault.jpg',
            actionType: 'renderComponent',
            actionValue: 'BiologyComponent',
        },
        {
            id: 2,
            name: 'Computer',
            img: 'https://i3.ytimg.com/vi/6v8djXa-IPQ/maxresdefault.jpg',
            actionType: 'renderComponent',
            actionValue: 'BiologyComponent',
        },
    ];
    const [data, setData] = useState(initialData);
    const [isInitialView, setIsInitialView] = useState(false);
    
    const resetData = () => {
        setData([...initialData]);
        setIsInitialView(true);
    };

    const handleDataChange = (newData) => {
        setData(newData);
        setIsInitialView(false);
    };

    const handleSelectTopic = (topic) => {
        setSelectedTopic(topic);
    };

    if (selectedTopic) {
        // Directly render the YT component if a topic is selected
        changeComponent('Personalized courses', { subject: selectedTopic }, false);
    }


    const SubjectCard = ({ subject, score }) => {
        const normalizedScore = score;  // Assuming score is a fraction
        

        return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ minWidth: 200, minHeight: 200, margin: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, textAlign: 'center', paddingBottom: '20px' }} color="text.secondary" gutterBottom>
                            {subject}
                        </Typography>
                        <Box position="relative" display="inline-flex">
                        <CircularProgress variant="determinate" value={normalizedScore} size={100} thickness={4} sx={{ color: 'black' }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                                <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: 14 }} >
                                    {`${Math.round(normalizedScore)}%`}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        );
    };
    if (selectedTopic) {
        return (
            <YT
                subject={selectedTopic}
                data={data}
                isInitialView={isInitialView}
                resetData={resetData}
                onDataChange={handleDataChange}
                view={"personalized"}
            />
        );
    }

    // Optional button to select a topic and go to personalized learning directly
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', display: 'flex', flexDirection:'column'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
                <Typography variant="h3" sx={{paddingTop: '30px'}}> Quiz Complete! </Typography>
                <Typography variant="h4" sx={{padding: '30px' ,textAlign: 'center'}}> You got {correctFirstAttempts} out of 10 correct. </Typography>
            <Grid container spacing={3} justifyContent="center">
                {Object.entries(scores).map(([subject, scoreData], index) => (
                    <SubjectCard key={index} subject={subject} score={scoreData.percentage} />
                ))}
            </Grid>
            </div>
            <div style = {{display: 'flex', marginBottom: '40px'}}>
            <Button onClick={() => handleSelectTopic(subject)} style={{display: 'flex', width: '100%' }}>Go to personalized learning</Button>
            </div>
        </div>
    );
}

export default QuizCompletePage;
