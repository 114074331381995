import React, { useState, useEffect } from 'react';
import { post } from 'aws-amplify/api';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import './YT.css';

const YT = ({ data, resetData, isInitialView, onDataChange, view, subject: initialSubject }) => {
    
    const [subject, setSubject] = useState(initialSubject || '');
    const [topicData, setTopicData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    
    useEffect(() => {
        if (initialSubject) {
            setSubject(initialSubject);
        }
    }, [initialSubject]);

    useEffect(() => {
        if (subject) {
            const fetchTopicData = async () => {
                setIsLoading(true);
                let endpoint = '/getSubjectResources';
                if (view === "personalized") {
                    endpoint = '/getWeaknesses';
                }
                const apiData = await callAPI(endpoint);
                setTopicData(apiData);
                if (!isInitialLoad) {
                    onDataChange(apiData);
                }
                setIsLoading(false);
                setIsInitialLoad(false);
            };
    
            fetchTopicData();
        }
    }, [subject]);
    
    const callAPI = async (endpoint) => {
        const APIName = "AdaptiLearnRestAPI";
        const response = post({
            apiName: APIName,
            path: endpoint,
            options: {
                body: { "subject": subject }
            }
        });
        const status = (await response.response).statusCode;
        const reply = (await response.response).body;
        if (await status === 201) {
            return [];
        }
        const json = await reply.json();
        return json;
    }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAction = (item) => {
        if (item.actionType === 'redirect') {
            window.location.href = item.actionValue;
        } else if (item.actionType === 'renderComponent') {
            setSubject(item.name);
            onDataChange(topicData);
        }
    };

    const handleReset = () => {
        setSubject(null);
        setTopicData([]);
        resetData();
    }

    const renderSkeleton = () => {
        return (
            <Grid container spacing={2}>
                {Array.from(new Array(6)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ width: '100%', height: {
                                    xs: 160,  // Applies to xs and up to sm
                                    md: 300   // Applies from md and up
                                }}}>
                            <Skeleton variant="rectangular" width= {'100%'}  height={'100%'} />  {/* Adjusted to full width and proper height for the image area */}
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Skeleton height={36} width={240} style={{ marginBottom: 8 }} />  {/* Full width skeleton for the title */}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    }
    


    const renderData = () => {
        return (
            <Grid container spacing={2}>
                {(subject ? topicData : data).map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.id} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleAction(item)}>
                        <Card sx={{ width: '100%' }}>
                            <CardMedia
                                component="img"
                                image={item.img}
                                alt={item.name}
                                sx={{height: {
                                    xs: 160,  // Applies to xs and up to sm
                                    md: 300   // Applies from md and up
                                }}}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <div className="yt-container" style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
            {!isInitialView && (
                <div className="yt-button-container">
                    {!isLoading && <button onClick={handleReset} style={{ background: 'transparent', border: 'none', textDecoration: 'underline' }}>{'< Back'}</button>}
                </div>
            )}
            <h1 style={{ marginBottom: '1rem' }}>{isInitialView ? (view === "personalized" ? "Select your personalized Course" : "Select a Course") : subject}</h1>
            {isLoading ? renderSkeleton() : renderData()}
        </div>
    );
};

export default YT;
