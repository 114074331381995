import AuthenticatorWrapper from '../AuthenticatorWrapper';
import './HomePage.css';
import ResponsiveDrawer from './Drawer';



function HomePage() {
// when google signs in, redirects is weirds, normal sign in doesnt do it.
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>

      <AuthenticatorWrapper>
        {({ signOut, user }) => (
          <>
            <ResponsiveDrawer signOut={signOut}/>
          </>
        )}
      </AuthenticatorWrapper>
    </div>
  );
}

export default(HomePage);