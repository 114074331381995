import React from 'react';
import { ReactComponent as Worldmap } from '../../Resources/World_map_with_points.svg';
import './ContactUs.css'; // Import the CSS file
import Typography from '@mui/material/Typography'; // Import Typography component

const ContactUs = () => {
    return (
        <div className="contact-us">
            <Typography variant="h4" component="h1" style={{ paddingBottom: "2%", paddingTop: "2%", textAlign: 'center' }}>
                We'd love to hear from you
            </Typography>
            <Worldmap className='world-map' />
            <div className="contact-us-info">
                <Typography variant="h6" component="h2" style={{ fontWeight: '700' }}>
                    Support
                </Typography>
                <Typography variant="h6" style={{ fontWeight: '400' }}>
                    We are here to help you
                </Typography>
                <Typography variant="body1" component="a" href="mailto:abubakarx72@gmail.com" style={{ textDecoration: 'none' }}>
                    abubakarx72@gmail.com
                </Typography>
            </div>
        </div>
    );
};

export default ContactUs;
