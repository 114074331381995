import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import mission from '../Resources/Mission.jpg';
import foundingStory from '../Resources/FoundingStory.jpg';

function ListWithImages() {

  const items = [
    {
      header: 'Mission',
      content: [
        'At AdaptiLearn, we are on a mission to revolutionize the educational landscape by championing the transformative power of adaptive learning technologies. Unlike traditional educational models that deploy a one-size-fits-all approach, our application meticulously tailors the learning experience to meet the unique needs and pace of each student. Through intelligent algorithms and real-time feedback, we ensure that every learner is not just a participant, but an active architect of their educational journey. Join us as we turn the page from conventional education to a future where every student thrives through personalized learning paths crafted just for them—because when education adapts to the learner, everyone reaches their potential.'
      ],
      image: mission
    },
    {
      header: 'Founding Story',
      content: [
        'The founding story of AdaptiLearn is both poignant and powerful: it began with a student burdened by the dual demands of work and personal commitments, struggling to navigate the traditional educational pathways that could not accommodate their time constraints and varied learning needs. As deadlines loomed and content piled up, the realization dawned that the prevailing educational model was failing them—not due to a lack of effort or capability, but because of its inflexibility and one-size-fits-all approach. This experience lit the spark of innovation, highlighting a universal challenge faced by many working students who also found themselves ensnared in a similar struggle. The need was clear: a learning system that not only understood but adapted to the individual\'s lifestyle and learning pace.',
        'AdaptiLearn emerged as a beacon of hope for those striving to balance life’s responsibilities with their educational ambitions. This application harnesses the latest in adaptive learning technologies to tailor the educational experience to each user’s unique needs, dynamically adjusting content and pacing based on real-time performance and feedback. It is not merely a tool, but a transformative platform designed to optimize learning efficiency and effectiveness for every user. With AdaptiLearn, students are empowered to not just manage, but excel in their studies, creating a personalized pathway that progresses with them. By turning traditional education on its head, AdaptiLearn ensures that learning is not just accessible, but genuinely tailored to fit the lives and ambitions of its users, setting a new standard for educational excellence.'
      ],
      image: foundingStory
    },
    // Add more items as needed
  ];

  const theme = useTheme();

  return (
    <Box>
      {items.map((item, index) => (
        <Box key={index} sx={{
          margin: theme.spacing(2),
          padding: theme.spacing(2),
          flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row-reverse' : 'row' },
          display: 'flex',
          alignContent:'center',
          alignItems:'center'
        }}>
          <Box flex="1" sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', paddingBottom: '10px'}}>{item.header}</Typography>
            {item.content.map((paragraph, idx) => (
              <Typography key={idx} variant="h6" sx={{ marginBottom: theme.spacing(1) , textAlign:'justify'}}>{paragraph}</Typography>
            ))}
          </Box>
          <Box
            component="img"
            src={item.image}
            sx={{
              width: '100%', // Full width for small and medium screens
              height: 'auto', // Maintain aspect ratio
              borderRadius: '25%', // Modern rounded corners
              maxWidth: { xs: '100%', sm: '100%', md: '50%' } // Adapt max width
            }}
          />
        </Box>
      ))}
    </Box>
  );
}

export default ListWithImages;
