import React, { useContext, useState, useEffect, useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import SearchContext from './SearchContext'; // Assuming you have a SearchContext defined

function DBAreaCharts() {
  const { searchItem, dashboardData } = useContext(SearchContext);
  const graphData = dashboardData[searchItem] ? dashboardData[searchItem].AreaChart : [];

  const [data, setData] = useState([]);
  useEffect(() => {
    // Adding index to each data point and incrementing by 1
    const indexedData = graphData.map((item, index) => ({ ...item, run: index + 1 }));
    setData(indexedData);
  }, [graphData]);

  const colors = ['#ff0000', '#00cc00', '#0000ff'];

  const sortedKeys = useMemo(() => {
    const firstItem = data[0] || {};
    return Object.keys(firstItem)
      .filter(key => key !== 'day' && key !== 'totalScore' && key !== 'run') // Exclude 'day', 'totalScore', and 'run'
      .sort(); // Alphabetical sorting
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          <p className="label">{`Run: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}%`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%" minHeight={"200px"} minWidth={"300px"}>
        <AreaChart
          data={data}
          margin={{ top: 10, left: -60}}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="run" tickFormatter={(value) => `Run ${value}`} />
          <YAxis domain={[0, 100]} label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
          <Tooltip content={<CustomTooltip />} position={{ y: 0 }} />
          <Legend />
          {sortedKeys.map((key, index) => (
            <Area
              key={key}
              type="monotone"
              dataKey={key}
              stroke={colors[index % colors.length]}
              fill={colors[index % colors.length]}
              dot={{
                fill: colors[index % colors.length],
                stroke: '#fff',
                strokeWidth: 2
              }}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DBAreaCharts;
