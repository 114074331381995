import React, { useEffect, useState,useContext, } from 'react';
import './Content2.css';
import PieChartViz from './PieChartViz';
import DBAreaCharts from './DBAreaCharts.js';
import Trial from './trial.js';
import ThreeCardLayout from './ThreeCardLayout.js';
import SearchContext from './SearchContext';
import { post } from 'aws-amplify/api';
import { Box, CircularProgress, Typography,IconButton,Grid } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const options = ['Science', 'Math', 'Computer']; // Example options
export function OptionSelector({ initialSubject = null }) {
  const { setSearchItem } = useContext(SearchContext);
  const options = ['Science', 'Math', 'Computer'];
  const initialIndex = options.indexOf(initialSubject);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(initialIndex >= 0 ? initialIndex : 0);

  useEffect(() => {
      setSearchItem(options[currentOptionIndex]);
  }, [currentOptionIndex, setSearchItem]);

  const handleNext = () => {
      setCurrentOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
  };

  const handlePrevious = () => {
      setCurrentOptionIndex((prevIndex) => {
        return (prevIndex - 1 + options.length) % options.length;
      });
  };

  return (
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, border: '1px solid black', borderRadius: '20px', marginLeft: 'auto', minWidth: '200px' }}>
          <IconButton onClick={handlePrevious} size="medium">
              <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
              {options[currentOptionIndex]}
          </Typography>
          <IconButton onClick={handleNext} size="medium">
              <ArrowForwardIosIcon />
          </IconButton>
      </Box>
  );
}




function LoadingComponent() {
  return (
      <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: `100%`, // Subtract the height of the navbar
          width: '100%'
      }}>
          <CircularProgress sx={{ color: 'black' }}/>
          <Typography variant="h6" sx={{ marginTop: 2 }}>
              Loading...
          </Typography>
      </Box>
  );
}

function Dashboard() {

    return (
        <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                <Typography sx={{ fontSize: 24, paddingTop: '20px', fontWeight: 'bold' }} color="text.primary" gutterBottom> Dashboard</Typography>
                    <Typography sx={{ fontSize: 11, fontWeight: 'bold', paddingBottom:'20px' }} color="text.secondary" gutterBottom> Your personal space to learn and grow</Typography>
                <OptionSelector/>
                </div>
                <Typography sx={{ fontSize: 18, paddingBottom: '20px', fontWeight: 'bold' }} color="text.primary" gutterBottom>
                Topic Distribution Area Chart
                </Typography>
                <Grid container spacing={10}>
                    {/* DBAreaCharts - First in large screens, also first in phone view */}
                    <Grid item xs={12} sm={12} md={12} lg={9} order={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
                        <DBAreaCharts />
                    </Grid>
                    {/* Trial - Second in large screens, third in phone view */}
                    <Grid item xs={12} sm={12} md={12} lg={3} order={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                        <Trial />
                    </Grid>
                    {/* ThreeCardLayout - Third in large screens, last in phone view */}
                    <Grid item xs={12} sm={12} md={12} lg={9} order={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <ThreeCardLayout />
                    </Grid>
                    {/* PieChartViz - Last in large screens, second in phone view */}
                    <Grid item xs={12} sm={12} md={12} lg={3} order={{ xs: 3, sm: 3, md: 3, lg: 4 }}>
                        <PieChartViz />
                    </Grid>
                </Grid>
        </div>
    );
}
export default function Content2() {
const { isLoaded, setIsLoaded, setDashboardData } = useContext(SearchContext);

useEffect(() => {
  const loadData = async () => {
      setIsLoaded(false);
      const APIName = "AdaptiLearnRestAPI";
      const apiPath = '/getDashboardData';
      try {
          const response = await post({ apiName: APIName, path: apiPath });
          const reply = (await response.response).body;
          const data = await reply.json();
          setDashboardData(data); // Set the dashboard data (assuming it's an object)
          setIsLoaded(true);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  loadData();
}, [setDashboardData, setIsLoaded]);

    return (
        <div style={{height:'100vh', width:'100vw'}}>
          {/* <LoadingComponent /> */}
            {isLoaded ? <Dashboard /> : <LoadingComponent />}
        </div>
    );
}

