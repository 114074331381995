import React, { useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import CardsContainer from './Card/CardContainer';
import TitleContainer from './title/title';
import ReviewContainer from './review/reviewContainer';
import FAQ from './FAQ/faq';
import ContactUs from './ContactUs/contactus';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { useContext } from 'react';
import SearchContext from '../Content/SearchContext';

function LandingPage() {
    const { isLoggedIn, setIsLoggedIn } = useContext(SearchContext);
    const navigate = useNavigate();
    useEffect(() => {
        async function currentAuthenticatedUser() {

            try {
          
              const { username, userId, signInDetails } = await getCurrentUser();
          
              setIsLoggedIn(true);
              if (isLoggedIn === true && navigate('/homepage'));

            } catch (err) {
                console.log(err);
                        }
          
          }

          currentAuthenticatedUser();
    }, []); // Empty dependency array ensures this runs only once on component mount

    return (
        <div className="flex-container">
            {/* <NavBar signOut={signOut}/> */}
            <TitleContainer />
            <div className="main-content">
                <CardsContainer />
            </div>
            <ReviewContainer />
            <div className="landingPage-SecondHalf">
                <FAQ />
                <ContactUs />
                {/* <button onClick={callAPI}>call api</button> */}
            </div>
        </div>
    );
}

export default LandingPage;
