import './title.css';
import Button from '../button';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router
import { useComponent } from '../../ComponentContext'; // Adjust the path as necessary to import your context
import { Typography } from '@mui/material';
import gif from '../../../Resources/MIT-Neural-Networks-01.gif';

export default function TitleContainer() {
  const navigate = useNavigate();
  const { changeComponent } = useComponent(); // using the context

  const getLearning = () => {
    changeComponent('Courses'); // Change the component to the homepage
    navigate('/homepage');
  };
  

  return (
    <div className="gif-container">
      <img src={gif}/>
      <div className="text-overlay">
        <Typography variant="h1" style={{ textAlign: 'center', fontWeight: 600, paddingBottom:'10px' }}>
          AdaptiLearn
        </Typography>
        <Typography variant="h4" style={{ textAlign: 'center', padding: '0 5%' , paddingBottom:'20px'}}>
        Unlock your full potential with AdaptiLearn, the leading adaptive learning platform that customizes education to fit your schedule and learning style. Experience a tailored educational journey that enhances understanding and maximizes efficiency, setting you on the path to success.
        </Typography>
        <button onClick={getLearning} className='transparent-button'>Get Learning</button>
      </div>
    </div>
  );
  
}
