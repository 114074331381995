import React from 'react';
import {ReactComponent as Courses} from '../../Resources/CoursesImage.svg'
import {ReactComponent as PersonalisedCourses} from '../../Resources/PersonalizedCoursesImage.svg'
import {ReactComponent as TakeAQuiz} from '../../Resources/QuizImage.svg'
import { Grid, Card, CardContent, Typography, CardActionArea } from '@mui/material';
import { useComponent } from '../ComponentContext';
import SearchContext from './SearchContext';
import { useContext } from 'react';
import { useEffect, useRef } from 'react';

function ThreeCardLayout() {
    const { searchItem } = useContext(SearchContext);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const svgs = ref.current.querySelectorAll('svg');
            svgs.forEach((svg, index) => {
                const ids = svg.querySelectorAll('[id]');
                ids.forEach(id => {
                    const originalId = id.getAttribute('id');
                    id.setAttribute('id', `${originalId}_${index}`);
                });
                const uses = svg.querySelectorAll('use');
                uses.forEach(use => {
                    let href = use.getAttribute('xlink:href');
                    if (href) {
                        const newHref = `${href}_${index}`;
                        use.setAttribute('xlink:href', newHref);
                    }
                    href = use.getAttribute('href'); // for modern browsers
                    if (href) {
                        const newHref = `${href}_${index}`;
                        use.setAttribute('href', newHref);
                    }
                });
            });
        }
    }, []);
    const data = [
        {
            id: 0,
            name: 'Course',
            SvgIcon: Courses, // Renamed from img to SvgIcon for clarity
            actionType: 'renderComponent',
            actionValue: 'Courses',
        },
        {
            id: 1,
            name: 'Personalised Course',
            SvgIcon: PersonalisedCourses,
            actionType: 'renderComponent',
            actionValue: 'Personalized courses',
        },
        {
            id: 2,
            name: 'Take a quiz',
            SvgIcon: TakeAQuiz,
            actionType: 'renderComponent',
            actionValue: 'Quiz',
        },
    ];
    const { changeComponent } = useComponent(); // using the context
    return (
      <div ref={ref}>
            <Grid container spacing={2}>
                {data.map((item) => (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={item.id} sx={{ display: 'flex', justifyContent: 'center', marginLeft:'auto',marginRight:'auto', marginTop:'30px', textAlign:'center'}}>
                        <Card>
                            <CardActionArea onClick={() => { changeComponent(item.actionValue, { subject: searchItem }, false);}}>
                                <div style={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    <item.SvgIcon style={{ width: 200, height: 200 }}/>
                                </div>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {item.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default ThreeCardLayout;
